import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchEvents(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/events', {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addEvent(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/events', data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateEvent(ctx, {id, data}) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/events/' + id, data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deleteEvent(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete('/events/' + id)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchEvent(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/events/' + id)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        }
    }
}
